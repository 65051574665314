const defaultRole = {
    sideBarNav: [
        { text: 'map', img: require('@/assets/svg/sideBar/icon-side-bar-1.svg'), path: '/' },
        { text: 'rating-of-activists',img: require('@/assets/svg/sideBar/icon-side-bar-2.svg'), path: {path: '/activists-rating', query: {type: 'unconfirmed'}}  },
        { text: 'rating-of-regions',img: require('@/assets/svg/sideBar/icon-side-bar-3.svg'), path: {path: '/data-region', query: {type: 'unconfirmed'}}  },
        { text: 'partners',img: require('@/assets/svg/sideBar/icon-side-bar-6.svg'), path: '/partners'  },
        { text: 'technical-support-1',img: require('@/assets/svg/sideBar/icon-side-bar-7.svg'), path: 'tech-support'  },
        { text: 'contacts',img: require('@/assets/svg/sideBar/icon-side-bar-8.svg'), path: 'contacts' },
        { text: 'about-app',img: require('@/assets/svg/sideBar/icon-side-bar-9.svg'), path: 'about'  },
    ],
}

export default  defaultRole
