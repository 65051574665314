const moderator = {
    icon: require('@/assets/svg/roles/active.svg'),
    text: 'moderator',
    sideBarNav: [
        { text: 'work-requests', img: require(`@/assets/svg/sideBar/icon-side-bar-10.svg`), path:'/work-requests' },
        { text: 'sustainable-development-projects', img: require(`@/assets/svg/sideBar/icon-side-bar-15.svg`), path:'/esg' },
        { text: 'map', img: require(`@/assets/svg/sideBar/icon-side-bar-1.svg`), path:'/' },
        { text: 'rating-of-activists',img: require(`@/assets/svg/sideBar/icon-side-bar-2.svg`), path:'/activists-rating' },
        { text: 'rating-of-regions',img: require(`@/assets/svg/sideBar/icon-side-bar-3.svg`), path:'/data-region'},
        { text: 'participants', img: require(`@/assets/svg/sideBar/icon-side-bar-11.svg`), path: '/members' },
        // { text: 'Программа лояльности', img: require(`@/assets/svg/sideBar/icon-side-bar-13.svg`), path:'/' },
        // { text: 'Партнерская программа',img: require(`@/assets/svg/sideBar/icon-side-bar-12.svg`), path:'/' },
        { text: 'events',img: require(`@/assets/svg/sideBar/icon-side-bar-5.svg`), accordion: true },
        { text: 'partners',img: require(`@/assets/svg/sideBar/icon-side-bar-6.svg`), path:'/partners' },
        { text: 'technical-support-1',img: require(`@/assets/svg/sideBar/icon-side-bar-7.svg`), path:'/tech-support' },
        { text: 'contacts',img: require(`@/assets/svg/sideBar/icon-side-bar-8.svg`), path:'/contacts' },
        { text: 'about-app',img: require(`@/assets/svg/sideBar/icon-side-bar-9.svg`), path:'/about' },
    ],
    workRequestNavBar: [
        { text: 'all' },
        { text: 'new', status: 'moderation' },
        { text: 'requests', status: 'request' },
        { text: 'in-progress', status: 'accepted' },
        { text: 'rejected-2', status: 'rejected' },
        { text: 'closed-2', status: 'closed' },
    ],
    workRequestStatus: ['Accepted', 'Closed', 'Rejected', 'Moderation'],
    workRequestDynamicActive: ['Accepted', 'Closed', 'Rejected', 'Moderation', 'Draft'],
    membersNavBar: [
        { text: 'all', path: '/members', status: 'All' },
        { text: 'new', path: '/members?status=new', status: 'NeedModeration' },
        { text: 'inspectors', path: '/members?status=inspector',status: 'Inspector' },
        { text: 'eco-lawyers', path: '/members?status=lawyer', status: 'Lawyer' },
        { text: 'business', path: '/members?status=company', status: 'Company' },
        { text: 'public-authorities', path: '/members?status=government', status: 'Government' },
        { text: 'rejected-2', path: '/members?status=rejected', status: 'Rejected' },
        { text: 'deleted', path: '/members?status=deleted', status: 'Deleted' },
    ],
    memberStatus: ['Lawyer', 'Inspector', 'Government', 'Company'],
    memberType: ['Lawyer', 'Inspector', 'Government', 'Company', 'Deleted'],
    esgNavBar: [
        { text: 'view-requests', path: '/esg' },
        { text: 'sustainability-project-directories', path: '/esg-guides/project-categories' },
    ]
}

export default  moderator
