const active = {
    icon: require('@/assets/svg/roles/active-2.svg'),
    text: 'eco-activist',
    visibleRating: true,
    // workRequestNavBar: [
    //     { text: 'Все' },
    //     { text: 'В работе', status: 'accepted' },
    //     { text: 'Закрытые', status: 'closed' }
    // ],
    sideBarNav: [
        { text: 'my-requests', img: require(`@/assets/svg/sideBar/icon-side-bar-10.svg`), path:'/work-requests' },
        // { text: 'Проекты по устойчивому развитию', img: require(`@/assets/svg/sideBar/icon-side-bar-15.svg`), path:'' },
        { text: 'map', img: require(`@/assets/svg/sideBar/icon-side-bar-1.svg`), path:'/' },
        { text: 'rating-of-activists', img: require(`@/assets/svg/sideBar/icon-side-bar-2.svg`), path:'/activists-rating' },
        { text: 'rating-of-regions', img: require(`@/assets/svg/sideBar/icon-side-bar-3.svg`), path:'/data-region',},
        // { text: 'Программа лояльности',img: require(`@/assets/svg/sideBar/icon-side-bar-4.svg`), path:'/' },
        { text: 'events', img: require(`@/assets/svg/sideBar/icon-side-bar-5.svg`), path:'/event' },
        { text: 'partners', img: require(`@/assets/svg/sideBar/icon-side-bar-6.svg`), path:'/partners' },
        { text: 'technical-support-1', img: require(`@/assets/svg/sideBar/icon-side-bar-7.svg`), path:'/tech-support' },
        { text: 'contacts', img: require(`@/assets/svg/sideBar/icon-side-bar-8.svg`), path:'/contacts' },
        { text: 'about-app', img: require(`@/assets/svg/sideBar/icon-side-bar-9.svg`), path:'/about' },
    ],
    workRequestNavBar: [
        { text: 'all' },
        { text: 'open-2', status: 'accepted' },
        { text: 'closed-2', status: 'closed' },
        { text: 'under-moderation', status: 'moderation' }
    ],
    workRequestStatus: ['Accepted', 'Closed', 'Moderation'],
    workRequestStatusUser: [
        { text: 'all' },
        { text: 'open-2', status: 'accepted' },
        { text: 'closed-2', status: 'closed' },
    ],
    membersNavBar: [
        { text: 'all', path: '/members', status: "All" },
        { text: 'eco-lawyers', path: '/members?status=lawyer', status: "Lawyer" },
        { text: 'inspectors', path: '/members?status=inspector', status: "Inspector" },
        { text: 'business', path: '/members?status=company', status: "Company" },
        { text: 'public-authorities', path: '/members?status=government', status: "Government" }
    ],
    memberStatus: ['Company', 'Government', 'Inspector', 'Lawyer'],
    memberType: ['Lawyer', 'Inspector', 'Government', 'Company']
}

export default  active
