const lawyer = {
    icon: require('@/assets/svg/roles/goverment.svg'),
    text: 'public-authority',
    sideBarNav: [
        { text: 'work-requests', img: require(`@/assets/svg/sideBar/icon-side-bar-10.svg`), path:'/work-requests' },
        { text: 'sustainable-development-projects', img: require(`@/assets/svg/sideBar/icon-side-bar-15.svg`), path:'/esg' },
        { text: 'map', img: require(`@/assets/svg/sideBar/icon-side-bar-1.svg`), path:'/' },
        { text: 'rating-of-activists',img: require(`@/assets/svg/sideBar/icon-side-bar-2.svg`), path:'/activists-rating'},
        { text: 'rating-of-regions',img: require(`@/assets/svg/sideBar/icon-side-bar-3.svg`), path:'/data-region'},
        { text: 'participants', img: require(`@/assets/svg/sideBar/icon-side-bar-11.svg`), path:'/members' },
        { text: 'events',img: require(`@/assets/svg/sideBar/icon-side-bar-5.svg`), path:'/event' },
        { text: 'partners',img: require(`@/assets/svg/sideBar/icon-side-bar-6.svg`), path:'/partners' },
        { text: 'technical-support-1',img: require(`@/assets/svg/sideBar/icon-side-bar-7.svg`), path:'/tech-support' },
        { text: 'contacts',img: require(`@/assets/svg/sideBar/icon-side-bar-8.svg`), path:'/contacts' },
        { text: 'about-app',img: require(`@/assets/svg/sideBar/icon-side-bar-9.svg`), path:'/about' },
    ],
    workRequestNavBar: [
        { text: 'all' },
        { text: 'new', status: 'new' },
        { text: 'requests', status: 'request' },
        { text: 'in-progress', status: 'accepted' },
        { text: 'closed-2', status: 'closed' }
    ],
    workRequestStatus: ['Accepted', 'Closed'],
    membersNavBar: [
        { text: 'all', path: '/members', status: 'All' },
        { text: 'public-authorities', path: '/members?status=government', status: 'Government' },
        { text: 'business', path: '/members?status=company', status: 'Company' },
    ],
    memberStatus: ['Company', 'Government'],
    memberType: ['Government', 'Company'],
    esgNavBar: [
        { text: 'view-requests', path: '/esg' },
        { text: 'to-announce-sustainable-development-project', path: '/add-esg' },
    ]
}

export default lawyer
